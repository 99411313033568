export default [
  {
    header: "Other Pages",
  },
  {
    title: "Users",
    route: "users",
    icon: "UsersIcon",
  },
  {
    title: "Categories",
    route: "categories",
    icon: "ListIcon",
  },
  {
    title: "Sliders",
    route: "sliders",
    icon: "ImageIcon",
  },
  {
    title: "Merchants",
    route: "merchants",
    icon: "ServerIcon",
  },
  {
    title: "Branches",
    route: "branches",
    icon: "Share2Icon",
  },
  {
    title: "Products",
    route: "products",
    icon: "ShoppingCartIcon",
  },
  {
    title: "Offers",
    route: "offers",
    icon: "FileTextIcon",
  },
  {
    title: "Cities",
    route: "cities",
    icon: "BarChart2Icon",
  },
  {
    title: "Settings",
    route: "settings",
    icon: "SettingsIcon",
  },
];
